import { useMemo } from "react"
import { Link, useParams } from "react-router-dom"
import PageNotFound from "../../errors/page-not-found"
import { MEMBERS } from "./members"


export default function MemberDetailsPage( ) {
    const {memberName, gouvernancePage} = useParams<string>()
    const member = useMemo( () => {
        return MEMBERS.find( member => member.name.toLowerCase().replaceAll(' ', '-') === memberName )
    } , [memberName])


    return(
        <>
            {
                !member ? <PageNotFound /> 
                :(
                    <div style={{backgroundColor : '#fff'}}>
                        <section style={{backgroundColor : 'rgba(42, 45, 124, .9)', height : '12rem'}} />

                        <section style={{marginTop : '-5rem', paddingBottom : '2rem'}}>
                            <div className="container" >
                                <div className="profile-head">
                                    <img src={member.img} style={{width : '10rem', height : '10rem', borderRadius : '50%'}} alt="" />
                                    <div style={{transform : 'translateY(-3rem)'}}>
                                        <h1 style={{color : '#fff', fontWeight : 'bold', fontSize : '3rem', marginBottom : ''}}>{member.name}</h1>
                                        <span className="" style={{fontSize : '1.7rem', transform : 'translateY(2rem'}}>{member.title}</span>
                                    </div>
                                </div>
                                <div className="">
                                    <p className="lead" style={{textAlign : 'justify'}}>{member.details}</p>
                                    <Link to={`/gouvernance/${gouvernancePage}`} className="btn" style={{color : '#fff', backgroundColor : '#2a2d7c'}}>
                                        Retourner sur la liste
                                    </Link>
                                </div>
                            </div>
                        </section>
                    </div>
                )
            }
        </>
    )
};


{/* <section style={{backgroundColor : '#fff', paddingTop : '2rem', paddingBottom : '2rem'}}>
                        <div className="container" >
                            <div className="row" style={{}}>
                                <div className="col-sm-12 col-md-6">
                                    <img src={member.img} className='image-fluid' alt="" style={{width : '100%', minHeight : '30rem', maxHeight : '100%', objectFit : 'cover', objectPosition : 'left'}} />
                                </div>
                                <div className="col-sm-12 col-md-6">
                                    <h1 style={{paddingTop : '1rem'}}>{member.name}</h1>
                                    <p className="lead" style={{marginTop : '-.5rem', fontWeight : '500'}}>{member.title}</p>
                                    <p className="lead" style={{textAlign : 'justify'}}>{member.details}</p>

                                    <Link to={`/gouvernance/${gouvernancePage}`} className="btn btn-primary">
                                        Retourner sur la liste
                                    </Link>
                                </div>
                                
                            </div>
                        </div>

                    </section> */}