export enum Teams {
    CONSEIL_ADMINISTRATION = 'conseil-d-administration',
    COMITE_DE_DIRECTION = 'comite-de-direction',
    COMITE_DE_PILOTAGE = 'comite-de-pilotage',
}

export type Member ={
    team : Teams[],
    name : string,
    title : string,
    overview? : string,
    details? : string,
    img? : string
}

export const MEMBERS : Member[] = [
    {
        team : [Teams.CONSEIL_ADMINISTRATION, Teams.COMITE_DE_PILOTAGE, Teams.COMITE_DE_DIRECTION] ,
        name : 'LAURENT BIEN LEGBANE',
        title : 'Président Directeur Général',
        overview : '',
        details : `Laurent Bien LEGBANE est un Banquier d’affaire & D’investissement, Président Directeur général CFBANK Plc depuis mai 2019. Il a eu un parcours varié au sein de plusieurs grands groupes tant dans le secteur de la finance que celui de l’industrie pétrolière depuis plusieurs années. Il a occupé différents postes dans des fonctions métiers, de gestion ou de contrôle, en Afrique et à l’international, dans les activités de banque de financement et d’investissement comme de banque d’affaire.
        Il a rejoint Union Bank de Dubaï en 2012 en qualité de Consultant Expert en stratégie financière où il conduit diverses missions d'audit et de conseil avant d'intégrer le monde de l’entreprenariat. Il est diplômé d’un MBA, d’un diplôme d’ingénieur financier Américain University et un Master en finance ESSEC Douala Cameroun et est certifié CFA (Chartered Financial Analyst) puis d’un certification AMF
        `,

        img : '/img/team/laurent-bien.png',
    },
    {
       team : [Teams.CONSEIL_ADMINISTRATION, Teams.COMITE_DE_PILOTAGE, Teams.COMITE_DE_DIRECTION] ,
        name : 'Jean Christian GLOROIS',
        title : 'Vice-Président',
        overview : '',
        details : `Jean Christian est nommé Vice-Président CF BANQUE Plc en charge des investissements depuis mai 2021. Il était précédemment Directeur Délégué de Société Générale Private Banking et Directeur de Société Générale Private Banking France. Jean Christian rejoint la BNP en 2009 en tant qu’économiste.  Il occupe ensuite les fonctions de Responsable du Private Banking à Hong Kong, puis de Directeur Général de BNP Asset Management Asie.  Il revient à la banque privée en 2014 en tant que responsable régional pour l'Asie en charge du développement et prend en 2019, la direction de BNP Paribas Banque Privée en Italie. Il rejoint CF BANQUE Plc en 2021 dont il sera nommé Vice-Président en charge des Investments. Jean Christian est diplômé de l'Institut d'Etudes Politiques de Bordeaux, titulaire d'un DEA de Droit International Privé, d'un DEA de Sociologie Politique et d'un Doctorat de Finances Internationales (Université de Paris Dauphine).`,

        img : '/img/team/jean-christian.png',
    },
    {
        team : [Teams.CONSEIL_ADMINISTRATION, Teams.COMITE_DE_PILOTAGE, Teams.COMITE_DE_DIRECTION] ,
        name : 'EMILIO SANDROS',
        title : 'Vice-President Global Banking and Advisory',
        overview : '',
        details : `EMILIO est  nommé Vice-President Global Banking and Advisory en 2020 CF BANQUE Plc. Il était, depuis 2015, Responsable adjoint des activités de financement (Global Finance) et Responsable des activités de financement au Royaume-Uni UBS.
        EMILIO SANDROS  était Responsable de Société Générale Corporate & Investment Banking en 2018 en tant que Directeur du département Financements structurés en Espagne. Il y a occupé plusieurs fonctions avant de rejoindre Londres en Juin 2020.  D’abord en tant que Responsable adjoint de la Syndication puis, à partir de 2010, en tant que Responsable mondial de la Syndication. Avant de rejoindre le Groupe, EMILIO a passé 3 ans chez Nomura à Madrid où il était Directeur, en charge de la zone ibérique, puis à Londres en tant que Directeur du Groupe Corporate Finance. Il a également occupé différents postes au sein de la banque d’investissement de Banco Urquijo.
        EMILIO SANDROS est diplômé d’économie de CUNEF à Madrid et est titulaire d’un MBA de l’IESE à Madrid.
        `,
        img : '/img/team/emilio-sandros.png',
    },
    {
        team : [Teams.CONSEIL_ADMINISTRATION, Teams.COMITE_DE_PILOTAGE, Teams.COMITE_DE_DIRECTION] ,
        name : 'Jacqueline Guillaume',
        title : 'Vice-Présidente en charge du Risque',
        overview : '',
        details : `Chef Économiste du Groupe et Directrice des Études économiques et sectorielles
        Depuis le 1er septembre 2020, Jacqueline Guillaume occupe le poste de Vice-Présidente en charge du risque CF BANQUE Plc. Elle dirige également une équipe de plus de 30 économistes et analystes sectoriels en tant que Directrice des Etudes économiques et sectorielles pour la direction des Risques.  Jacqueline Guillaume est entrée dans le groupe CF BANQUE Plc en février 2020, et est membre du Comité de direction du Groupe. Forte d’une expérience de plus de 10 ans dans le secteur financier.
        Jacqueline Guillaume est titulaire d’un Master en Sciences Economiques de l’Université de Copenhague.
        `,
        img : '/img/team/jacqueline-guillaume.png',
    },
    {
        team : [Teams.CONSEIL_ADMINISTRATION, Teams.COMITE_DE_PILOTAGE, Teams.COMITE_DE_DIRECTION] ,
        name : 'Cloé MAVRE',
        title : 'Vice-Présidente en charge de la Direction de la banque d’affaire',
        overview : '',
        details : `Cloé MAVRE est Vice-Présidente en charge de la Direction de la banque d’affaire.
        Cloé MAVRE a une expérience de 13 ans dans le secteur des services financiers. Elle a débuté sa carrière au Crédit Lyonnais en tant qu’ingénieur financier sur les produits dérivés actions. En 2013 elle rejoint AXA où elle a occupé plusieurs postes de direction pendant 5 ans, dont 3 ans en Asie (Japon, Chine, Hong-Kong, Asie du Sud-Est et Inde). En juillet 2019, elle devient Directrice des activités d’assurances dommages du Groupe AXA, et membre du Comité de Direction du Groupe. Fin 2019, elle décide de quitter AXA pour apporter son aide à des startups spécialisées dans la data et l’innovation. En Juin 2021, elle rejoint le Groupe CF BANQUE Plc en tant que Vice-Président en charge des activités de banque d’affaire.
        Cloé MAVRE est diplômée de l'École Polytechnique, de l'ENSAE et de l'Institut des Actuaires.
        `,
        img : '/img/team/cloe-mavre.png',
    },
    {
        team : [Teams.CONSEIL_ADMINISTRATION, Teams.COMITE_DE_PILOTAGE, Teams.COMITE_DE_DIRECTION] ,
        name : 'JAMES SMITH',
        title : 'Vice-Président en charge de la conformité et du contrôle interne',
        overview : '',
        details : `JAMES SMITH débute sa carrière dans les activités de pilotage financier du groupe Cetelem. Il rejoint ensuite la Banque Indosuez en 2016 sur les activités de marché où il occupe différents postes à responsabilité à Paris et à Tokyo. Il intègre le groupe CF BANQUE Plc en Janvier 2019 à la Direction financière comme adjoint puis Directeur de la Gestion de bilan du Groupe. Il est nommé depuis le 1er septembre 2021, Vice-Président en charge de la Conformité et du contrôle interne du Groupe.
        JAMES SMITH est diplômé d’un MBA Américain University, et d’un Master en droit privé London University.
        `,
        img : '/img/team/james-smith.png',
    },
    {
        team : [Teams.CONSEIL_ADMINISTRATION, Teams.COMITE_DE_PILOTAGE, Teams.COMITE_DE_DIRECTION] ,
        name : 'Chersty Mabiala Bitsindou',
        title : 'Vice-Président en charge de la communication et de la politique commerciale',
        overview : '',
        details : `CHERSTY a été nommée Vice-Président en 2022 et Directrice de la clientèle de grandes Entreprises du réseau CF BANQUE Plc monde.  Elle était depuis 2020 Conseillère spéciale du Président Directeur Général du groupe CFBANQUE Plc en stratégie commerciale. Elle a occupé différents postes dans les directions commerciales des Grands groupes. 
        CHERSTY MABIALA est diplômé d’un Master en marketing de la London business school
        `,
        img : '/img/team/chersty-mabiala.png',
    },
    {
        team : [Teams.CONSEIL_ADMINISTRATION, Teams.COMITE_DE_PILOTAGE] ,
        name : 'George Oliver Lemouel',
        title : 'Vice-Président en charge de la trésorerie et du Budget',
        overview : '',
        details : `George Olivier a rejoint la banque de financement et d’investissement de CF BANQUE en 2019. Il a occupé différentes fonctions de management dans le métier de l’informatique et la gestion de projets dans les principales places en Afrique, aux ROYAUME UNIS et principalement en Asie. Entre 2007 et 2011, il assure le rôle de Chief Information Officer de la zone Asie Pacifique de SG CIB à Hong Kong. Il rejoint SG Global Solution Center à Bangalore en mai 2011 en tant que Chief Delivery Officer et assure en Janvier 2012 les fonctions de Chief Executive Officer de cette entité. Il a pris les fonctions de Directeur de la Transformation, Processus et Systèmes d’information (RESG/TPS) le 1er juillet 2015. 
        George Olivier est diplômé de l’université Paris IX Dauphine où il a obtenu un Master en Mathématique et système d’information.
        `,
        img : '/img/team/george-olivier.png',
    },
    {
        team : [Teams.CONSEIL_ADMINISTRATION] ,
        name : 'Jean-Yves Odong',
        title : 'Directeur Général Group',
        overview : '',
        details : `JEAN YVES ODONG a été nommé Directeur général de CF BANQUE Plc en juin 2021. Il était Directeur général délégué de International Finance and Investment Plc depuis mai 2018 et précédemment Responsable de la Clientèle grandes entreprises de la même société et auparavant Directeur du risque vista bank, où il avait fait toute sa carrière occupant plusieurs postes au siège et dans le réseau notamment comme Responsable de la Direction des Entreprises, Directeur général de bank de détail. JEAN YVES ODONG est diplômé de HEC Paris, Université Yaoundé II SOA`,
        img : '/img/team/jean-yves.png',
    },
    {
        team : [Teams.CONSEIL_ADMINISTRATION] ,
        name : 'Marie Louise Nkou',
        title : 'Directrice Générale délégué',
        overview : '',
        details : `MARIE LOUISE NKOU a rejoint CF BANQUE Plc en 2021, au sein du service Equity Capital Market de CF BANQUE Corporate and Investment Banking. Elle a effectué la majeure partie de sa carrière au sein de CITIBANK, notamment des départements Strategic and Acquisition Finance et Asset Based Finance. En 2010, elle a pris la tête de l’équipe en charge du monitoring du risque de crédit des activités de financement, avant de partir pour la Russie, où elle a occupé le poste de Responsable adjointe du département d’analyse de crédit Corporate de Rosbank. Nommée Responsable de l’équipe Project Export Finance au sein de la ligne métier Financement Export en 2015, elle est depuis 2021 Directrice générale délégué de CF BANQUE Plc. Elle est diplômée de l’École Centrale Paris.`,
        img : '/img/team/marie-louise.png',
    },
    
]