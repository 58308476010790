import  {  useMemo } from "react";
import { useParams } from 'react-router-dom';
import { Feature, MemberCard } from "../../../component";
import HeroBreadcrumb from "../../../component/hero-breadcrumb";
import PageNotFound from "../../errors/page-not-found";
import { Member, MEMBERS, Teams } from './members';

interface GouvernancePage{
    image? : string,
    name : string,
    title : string,
    description? : string,
    members : Member[]
}

const pages : GouvernancePage[] = [
    {
        name : Teams.COMITE_DE_DIRECTION,
        title : "Comité de direction",
        members : MEMBERS.filter( member => member.team.includes(Teams.COMITE_DE_DIRECTION)),
        description : "Nous vous présentons notre équippe des professionnels hautement qualifiés et expérimentés.",
    },
    {
        name : Teams.COMITE_DE_PILOTAGE,
        title : "Comité de pilotage",
        members : MEMBERS.filter( member => member.team.includes(Teams.COMITE_DE_PILOTAGE)),
        description : "Nous vous présentons notre équippe des professionnels hautement qualifiés et expérimentés."
    },
    {
        name : Teams.CONSEIL_ADMINISTRATION,
        title : "Conseil d'administration",
        members : MEMBERS.filter( member => member.team.includes(Teams.CONSEIL_ADMINISTRATION)),
        description : "Nous vous présentons notre équippe des professionnels hautement qualifiés et expérimentés."
    }
]

export default function Gouvernance() {
    const {gouvernancePage} = useParams<string>()
    const currentPage = useMemo( () => gouvernancePage ? pages.find(page => page.name === gouvernancePage) : undefined, [gouvernancePage] )
    
    return(
        <>
            {

            }
            {
                currentPage ? (
                    <>
                        <HeroBreadcrumb title={currentPage?.title!} heroImagePath={`custom/${currentPage?.name}.jpg`} heroImagePosition="bottom" />
                                    
                        <div className="content_info">
                            <div className="borders world-bg" />
                            <div className="title-vertical-line">
                                <h2>{currentPage?.title!}</h2>
                                <p className="lead">
                                    {currentPage?.description}
                                </p>
                            </div>
                            <section className="paddings">
                                <div className="container">
                                    <div className="row">
                                        {
                                            currentPage?.members.map((member, index) => <MemberCard key={`member-${index}`} member={member} />)
                                        }
                                    </div>
                                    <Feature />
                                </div> 
                            </section>  
                        </div>
                    </>
                )
                : <PageNotFound />
            }
        </>
    )
}