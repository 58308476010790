import { Link } from "react-router-dom";
import { Member } from "../pages/company/gouvernance/members";

type props ={
    member : Member
}
export default function MemberCard({member} : props) {
    
    return(
        <div className="col-sm-12 col-md-6 col-lg-4">
            <div className="item-team" style={{marginBottom : '2rem'}}>
                <Link to={`${member.name.toLowerCase().replaceAll(' ', '-')}`} className=""><img src={member.img} alt="" /></Link>
                <Link to={`${member.name.toLowerCase().replaceAll(' ', '-')}`} style={{textDecoration : 'none'}}>
                    <h4>{member.name}</h4>
                    <p className="truncate">{member.title}</p>
                </Link>
                <ul className="list-styles" />
                <p className="card-text truncate-multiline" style={{maxHeight : '5rem'}}>
                    {member.details}
                </p>
                <Link to={`${member.name.toLowerCase().replaceAll(' ', '-')}`} className="btn" style={{color : '#fff', backgroundColor : "#2a2d7c"}}>Découvrir</Link>
            </div>

        </div>
    )
};