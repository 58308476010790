import React from "react"
import { Link } from 'react-router-dom';

export default function TopBar() {

    return(
        <header id="header">
            <div  className="row">
                {/* <!-- Logo Area --> */}
                <div  className="col-md-4 col-lg-5">
                    <div  className="logo">
                        <a href="/">
                            <img src="/img/cf-bank-logo.png" alt="CF-BANK logo" style={{ height: 80, objectFit : 'contain'}} />
                        </a>
                        {/* <div  className="icon-logo">
                            <i  className="fa fa-university"></i>
                        </div>
                        <a href="/">
                            Coop Bank
                            <span>Your money is safe.</span>
                        </a> */}
                    </div>
                </div>

                {/* <!-- Login Area --> */}
                <LoginArea />
            </div>
        </header>
    )
}

function LoginArea(){
    return(
        <div  className="col-md-8 col-lg-7">
            <div  className="info-login">
                <div  className="head-info-login">
                    {/* <p>bientôt disponible</p> */}
                    <span style={{color : '#fff'}}>
                        Devenir client
                    </span>
                </div>
                <div  className="form-theme">
                    <form onSubmit={e => e.preventDefault()}>
                        <div  className="selector">
                            <select  className="guests-input">
                                <option>formulaire de financement</option>
                                {/* <option disabled>Foreigners Card</option> */}
                                {/* <option disabled>Tax identification number</option>                     */}
                                {/* <option disabled>Identity Card</option> */}
                                {/* <option disabled>Username</option> */}
                            </select>
                            <span  className="custom-select">formulaire de financement</span>
                        </div>
                        {/* <input type="number" placeholder="Document Number"  className="input" /> */}
                        <Link to='/pdfs/formulaire-demande-investissement.pdf' target='_blank' download type="submit"  className="btn"  style={{width : 'fit-content'}} >télécharger</Link>
                    </form>
                </div>
            </div>
        </div>
    )
}
