import React, {FunctionComponent} from "react";
import { Link } from "react-router-dom";
import { Feature } from "../../component";

const PageNotFound : FunctionComponent = () =>{
    return (
        <div>
            <div className="content_info" style={{background : "url('/img/custom/brainstorming.jpg')"}}>
                <div className="section-title-01 section-title-01-small">
                <div className="container">
                    <div className="row">
                    <div className="col-md-7">
                        <h3>404</h3>
                        <h5>Page inéxistante</h5>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="content_info">
                <div className="paddings">
                    <div className="container">
                        <div className="page-error">
                            <h1>404 <i className="fa fa-bomb" /></h1>
                            <hr className="tall" />
                            <p className="lead">Désolé! la page que vous recherchez n'existe pas</p>
                            <Link to="/" className="btn btn-lg btn-primary">Accueil</Link>       
                        </div>
                        <Feature />
                    </div>
                </div>
            </div>
        </div>

    )
}

export default PageNotFound